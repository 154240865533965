import { Button } from '@telia-company/tv.oneapp-web-ui';
import { StyledSubSectionLabel } from '../../../navigation.styles';
import {
  StyledSubSectionHelpLink,
  StyledButtonContainerHelpLink,
  StyledSubSectionHelpLinkWrapper,
  StyledSubSectionHelpLinkContent,
} from '../../Help.styles';

export type HelpLinkType = {
  labelText: string;
  labelContent?: string;
  url: string;
  buttonText: string;
};

export type HelpLinkProps = {
  link: HelpLinkType;
};

export const HelpLink = ({ link }: HelpLinkProps) => {
  const button = (
    <Button as={'a'} href={link.url} variant="secondary">
      {link.buttonText}
    </Button>
  );

  return (
    <StyledSubSectionHelpLink>
      <StyledSubSectionHelpLinkWrapper>
        <StyledSubSectionLabel>{link.labelText}</StyledSubSectionLabel>
        {link.labelContent && (
          <StyledSubSectionHelpLinkContent>{link.labelContent}</StyledSubSectionHelpLinkContent>
        )}
      </StyledSubSectionHelpLinkWrapper>
      <StyledButtonContainerHelpLink>{button}</StyledButtonContainerHelpLink>
    </StyledSubSectionHelpLink>
  );
};
