import { ApolloClient } from 'sdk';
import {
  GET_MAIN_MENU_QUERY,
  GET_REGIONAL_CHANNEL_QUERY,
  GET_USER_QUERY,
  UPDATE_CHILD_LOCK,
} from 'gql/queries';
import {
  GetMainMenuQuery,
  GetMainMenuQueryVariables,
  GetUserQuery,
  GetUserQueryVariables,
  UpdateChildLockMutationVariables,
  UpdateChildLockMutation,
  GetRegionalChannelsQuery,
  GetRegionalChannelsQueryVariables,
  GetMessagesQuery,
  GetMessagesQueryVariables,
  AssumeProfileMutationVariables,
  AssumeProfileMutation,
} from 'gql/generated';
import { GET_MESSAGES_QUERY } from 'gql/queries/getMessages.Query';
import { ASSUME_PROFILE_MUTATION } from 'gql/queries/assumeProfileMutation';

export const getMainMenu = () => {
  const apolloClient = ApolloClient.instance();
  return apolloClient.query<GetMainMenuQuery, GetMainMenuQueryVariables>({
    query: GET_MAIN_MENU_QUERY,
  });
};

export const getUser = () => {
  const apolloClient = ApolloClient.instance();
  return apolloClient.query<GetUserQuery, GetUserQueryVariables>({
    query: GET_USER_QUERY,
  });
};

export const updateChildLock = async ({
  enabled,
  newPinCode,
}: UpdateChildLockMutationVariables) => {
  const apollo = ApolloClient.instance();
  return await apollo.mutate<UpdateChildLockMutation, UpdateChildLockMutationVariables>({
    mutation: UPDATE_CHILD_LOCK,
    variables: {
      enabled,
      newPinCode,
    },
  });
};

export const assumeProfile = async (variables: AssumeProfileMutationVariables) => {
  const apollo = ApolloClient.instance();
  return await apollo.mutate<AssumeProfileMutation, AssumeProfileMutationVariables>({
    mutation: ASSUME_PROFILE_MUTATION,
    variables,
  });
};

export const getRegionalChannels = async (channelIds: string[]) => {
  const apolloClient = ApolloClient.instance();
  return apolloClient.query<GetRegionalChannelsQuery, GetRegionalChannelsQueryVariables>({
    query: GET_REGIONAL_CHANNEL_QUERY,
  });
};

export const getMessages = async () => {
  const apolloClient = ApolloClient.instance();
  return apolloClient.query<GetMessagesQuery, GetMessagesQueryVariables>({
    query: GET_MESSAGES_QUERY,
  });
};

export const clearGetUserCache = () => {
  const { cache } = ApolloClient.instance();
  cache.evict({ fieldName: 'user' });
  cache.gc();
};
