import { SectionBackButton, SectionSpinner } from 'components';
import { StyledSectionHeading, StyledSubSectionContainer } from 'pages/navigation.styles';
import { getMfaStatus, getUser, mfaChangeEvent, MfaStatus } from 'services/userService';
import { useFetchFunction } from 'hooks/useFetchFunction';
import { MfaStatusSubSection } from './components/MfaStatusSubSection';
import { ErrorSubSection } from 'components/ErrorSubSection/ErrorSubSection';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ChangeMfa = () => {
  const { loading, data, error, refresh } = useFetchFunction(getMfaStatus);
  const { data: user } = useFetchFunction(getUser);
  const [loadedData, setLoadedData] = useState<MfaStatus[] | null>(null);
  const { t } = useTranslation();

  const emailMfa = data?.find((mfa) => mfa.verificationMethod === 'email');
  const smsMfa = data?.find((mfa) => mfa.verificationMethod === 'sms');

  // To avoid flickering when reloading the page due to changes, we keep a separate state for the loaded data
  useEffect(() => {
    if (data) {
      setLoadedData(data);
    }
  }, [data]);

  useEffect(() => {
    const changeListener = mfaChangeEvent.on(() => refresh());
    return () => mfaChangeEvent.off(changeListener);
  }, [refresh]);

  const isInitialLoading = useMemo(() => loading && !loadedData, [loading, loadedData]);

  return (
    <>
      <StyledSectionHeading>{t('MFA_PAGE_HEADER')}</StyledSectionHeading>
      <StyledSubSectionContainer>
        {isInitialLoading ? (
          <SectionSpinner />
        ) : error ? (
          <ErrorSubSection />
        ) : (
          <>
            <MfaStatusSubSection
              verificationMethod="email"
              mfaStatus={emailMfa}
              defaultValue={user?.email}
            />
            <MfaStatusSubSection verificationMethod="sms" mfaStatus={smsMfa} />
          </>
        )}
      </StyledSubSectionContainer>
      <SectionBackButton to="/account" />
    </>
  );
};
