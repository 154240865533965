import { gql } from '@apollo/client';

export const GET_MESSAGES_QUERY = gql`
  query getMessages {
    messages {
      items {
        id
        content
        severity
        url
        filterPageIds
      }
    }
  }
`;
