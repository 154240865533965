import { Config } from '@telia-company/tv.common-sdk/dist/Config';
import { FooterDataType } from '../Footer';
import { ColumnLink } from '../column';
import { oneAppUrl } from 'utils';

export const footerTeliaDanish = (appLinks: ColumnLink[]): FooterDataType => ({
  copyrightText: ['© Telia · En del af Telia Company', 'Orgnr. 20 36 79 97'],
  footerColumns: [
    {
      heading: 'Telia TV',
      linkList: [...appLinks],
    },
    {
      heading: 'Information',
      linkList: [
        {
          link: `${Config.get('one-app-telia-url')}/privat/hjalp/kontakt-kundeservice/`,
          name: 'Kontakt Telia',
        },
        {
          link: oneAppUrl('/about'),
          name: 'Om tjenesten',
        },
        {
          link: `${Config.get('one-app-telia-url')}/privat/hjalp/underholdning/telia-tv/`,
          name: 'Support',
        },
        {
          link: oneAppUrl('/privacy-policy'),
          name: 'Fortrolighedserklæring',
        },
        {
          link: oneAppUrl('/cookie-policy'),
          name: 'Cookies',
        },
        {
          link: '',
          name: 'Cookie-indstillinger',
          type: 'button',
        },
      ],
    },
  ],
});
