import { useEffect, useRef, useState } from 'react';
import { MenuItem } from '../../hooks';
import { Chevron } from 'components/Chevron';
import { DropDownMenu } from './DropDownMenu';
import { StyledAvatar, StyledAvatarContainer } from './UserDropDown.styled';
import { User } from 'services/userService';

type UserDropDownProps = {
  user: User;
  menuItems: MenuItem[];
};

export const UserDropDown = ({ user, menuItems }: UserDropDownProps) => {
  const [open, setOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClick = (event: TouchEvent | MouseEvent) => {
      if (containerRef.current?.contains(event.target as Element)) {
        return;
      }
      setOpen(false);
    };

    const eventType = 'ontouchstart' in window ? 'touchstart' : 'mousedown';
    document.addEventListener(eventType, handleClick);
    return () => {
      document.removeEventListener(eventType, handleClick);
    };
  }, []);

  const currentProfile = user.profiles.find((profile) => profile.id === user.currentProfileId);

  return (
    <>
      <StyledAvatarContainer
        open={open}
        onClick={() => {
          setOpen((prev) => !prev);
        }}
        ref={containerRef}
      >
        {currentProfile ? (
          <>
            <StyledAvatar src={currentProfile.avatar.url} />
            {currentProfile.alias}
          </>
        ) : (
          user.username || user.email
        )}
        <Chevron open={open} />
        <DropDownMenu open={open} menuItems={menuItems} />
      </StyledAvatarContainer>
    </>
  );
};
