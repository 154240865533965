import styled from '@emotion/styled';
import { Button, mq } from '@telia-company/tv.oneapp-web-ui';
import {
  StyledButtonContainer,
  StyledSubSection,
  StyledSubSectionContent,
} from '../navigation.styles';

export const StyledSubSectionHelpLink = styled(StyledSubSection)`
  flex-wrap: wrap;
  flex-direction: column;
  ${mq.S`
    flex-direction: row;
  `};
`;

export const StyledSubSectionHelpLinkWrapper = styled.div`
  flex: 1;
`;

export const StyledSubSectionHelpLinkContent = styled(StyledSubSectionContent)`
  min-width: 15ch;
`;

export const StyledButtonContainerHelpLink = styled(StyledButtonContainer)`
  ${mq.S`
    margin-left: auto;
    align-self: auto
  `}
`;

export const StyledActivateYourTvButton = styled(Button)`
  width: 100% !important;
`;
