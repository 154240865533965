import { useTranslation } from 'react-i18next';
import { isFeatureEnabled } from 'featureToggles';
import { StyledSectionHeading } from '../navigation.styles';
import { OtherLinks, FeedbackAndFaq, ActivateYourTv } from './components';

const HelpHeader = () => {
  const { t } = useTranslation();
  return <StyledSectionHeading>{t('HELP_HEADING')}</StyledSectionHeading>;
};

export const Help = () => {
  const showOtherLinks = isFeatureEnabled(['Help.ShowOtherLinksEnabled']);
  return (
    <>
      <HelpHeader />
      <FeedbackAndFaq />
      {showOtherLinks && <OtherLinks />}
      <ActivateYourTv />
    </>
  );
};
