import { Config } from '@telia-company/tv.common-sdk/dist/Config';
import { FooterDataType } from '../Footer';
import { ColumnLink } from '../column';
import { oneAppUrl } from 'utils';

export const footerTeliaFinnish = (appLinks: ColumnLink[]): FooterDataType => ({
  copyrightText: ['© Telia · Osa Telia Finland', 'Oy:tä', 'Y-tunnus 1475607-9'],
  footerColumns: [
    {
      heading: 'Telia Play',
      linkList: [...appLinks],
    },
    {
      heading: 'Informaatio',
      linkList: [
        {
          link: oneAppUrl('/about'),
          name: 'Tietoja palvelusta',
        },
        {
          link: `${Config.get('one-app-telia-url')}/asiakastuki/tv-ja-viihde`,
          name: 'Tuki',
        },
        {
          link: `${Config.get('one-app-telia-url')}/asiakastuki/yhteystiedot`,
          name: 'Ota yhteyttä',
        },
        {
          link: oneAppUrl('/privacy-policy'),
          name: 'Tietosuojaseloste',
        },
        {
          link: oneAppUrl('/cookie-policy'),
          name: 'Evästeiden käyttö',
        },
        {
          link: '',
          name: 'Evästeasetukset',
          type: 'button',
        },
      ],
    },
  ],
});
