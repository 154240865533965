import { CacheManager } from '@telia-company/tv.common-sdk/dist/CacheManager';
import * as purchaseGateway from './backend/purchaseGateway';

export type SelectableItem = Omit<
  purchaseGateway.SelectableItemResponse,
  'storeId' | 'channelId'
> & {
  storeIds: string[];
  channelIds: string[];
};
export type FixedItem = purchaseGateway.FixedItemResponse;

export type SelectionMenu = Omit<purchaseGateway.SelectionMenuResponse, 'selectables'> & {
  selectables: SelectableItem[];
};

const cacheClass = 'selectionMenuService';

export const getSelectionMenu = async (): Promise<SelectionMenu> => {
  const [selectionMenuResponse, selectionPackagesResponse] = await Promise.all([
    purchaseGateway.getSelectionMenu({ cacheClass }),
    purchaseGateway.getSelectionPackages({ cacheClass }),
  ]);

  const selectables: SelectableItem[] = selectionMenuResponse.selectables.map(
    ({ externalRegistration, channelId, storeId, ...item }) => {
      const selectionPackage = item.tvPackageId
        ? selectionPackagesResponse[item.tvPackageId]
        : undefined;
      return {
        ...item,
        externalRegistration: externalRegistration ?? undefined,
        storeIds: selectionPackage?.storeIds ?? [],
        channelIds: selectionPackage?.channelIds ?? [],
      };
    },
  );

  const fixed = selectionMenuResponse.fixed.map(({ externalRegistration, ...item }) => ({
    ...item,
    externalRegistration: externalRegistration ?? undefined,
  }));

  return { ...selectionMenuResponse, selectables, fixed };
};

export const setSelectionMenu = async (ids: string[]): Promise<void> => {
  await purchaseGateway.setSelectionMenu({ ids, cacheClass });
  CacheManager.instance().clearCacheClass(cacheClass);
};
