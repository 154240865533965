import { ErrorComponent, Fullscreen } from 'components';
import {
  SelectionMenuGridView,
  SelectionMenuScoreTable,
  FilterOption,
  Filters,
  ItemModal,
  ItemModalHandle,
  SaveConfirmModal,
  SaveConfirmModalHandle,
} from './components';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFetchFunction } from 'hooks/useFetchFunction';
import { StyledContainer } from './SelectionMenu.styles';
import {
  FixedItem,
  SelectableItem,
  getSelectionMenu,
  setSelectionMenu,
} from 'services/selectionMenuService';
import { SelectionMenuContext } from './SelectionMenuContext';
import { areSelectedItemsDifferent } from './areSelectedItemsDifferent.utils';
import { useSearchParams } from 'react-router-dom';
import { useLogger, useSnackbars } from 'hooks';
import { useTranslation } from 'react-i18next';
import { SelectionMenuResponse } from 'services/backend/purchaseGateway';
import { getFilteredItems } from './getFiteredItems.utils';
import { getActivatableTvPackageIds } from './getActivatableTvPackageIds';

export const SelectionMenu = () => {
  const logger = useLogger('SelectionMenu');
  const { t } = useTranslation();

  const { data, error, refresh: refreshSelectionMenu } = useFetchFunction(getSelectionMenu);
  const [selectedFilter, setSelectedFilter] = useState<FilterOption>('STREAMING_SERVICES');
  const [selectedItems, setSelectedItems] = useState<SelectableItem[]>([]);
  const [activatableTvPackageIds, setActivatableTvPackageIds] = useState<string[]>();
  const { createNegativeSnackbar } = useSnackbars();
  const [dirty, setDirty] = useState(false);
  const [saving, setSaving] = useState(false);
  const [resetting, setResetting] = useState(false);
  const initialSelectedItemsRef = useRef<SelectableItem[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const itemModalRef = useRef<ItemModalHandle>(null);
  const saveConfirmModalRef = useRef<SaveConfirmModalHandle>(null);

  // Keep state of the selectables/fixed items and total points - because they
  // will probably not change during the save/reset, minimizing the screen-blinking
  const [cachedData, setCachedData] = useState<SelectionMenuResponse>();

  useEffect(() => {
    if (!data) {
      return;
    }

    // If we were resetting, we're now done
    if (resetting) {
      setResetting(false);
    }

    const channelId = searchParams.get('channelId');
    const storeId = searchParams.get('storeId');

    if (channelId) {
      const item = data.selectables.find((item) => item.channelIds.includes(channelId));
      if (item) {
        itemModalRef.current?.open(item);
      }
    }
    if (storeId) {
      const item = data.selectables.find((item) => item.storeIds.includes(storeId));
      if (item) {
        itemModalRef.current?.open(item);
      }
    }

    searchParams.delete('storeId');
    searchParams.delete('channelId');
    setSearchParams(searchParams);

    setCachedData(data);

    setActivatableTvPackageIds(getActivatableTvPackageIds(data));

    const selectedItems = data.selectables.filter((item) => item.isSelected);
    initialSelectedItemsRef.current = selectedItems.slice();
    setSelectedItems(selectedItems);
  }, [data, resetting, searchParams, setSearchParams]);

  const selectedPoints = useMemo(() => {
    let sumPoints = 0;
    for (const selectedItem of selectedItems) {
      sumPoints += selectedItem.points ?? 0;
    }
    return sumPoints;
  }, [selectedItems]);

  const newSelectedItems = useMemo(() => {
    return selectedItems.filter(({ id }) =>
      initialSelectedItemsRef.current.every(({ id: prevSelectedId }) => id !== prevSelectedId),
    );
  }, [selectedItems]);

  const oldSelectedItems = useMemo(() => {
    return selectedItems.filter(({ id }) =>
      newSelectedItems.every(({ id: newSelectedId }) => id !== newSelectedId),
    );
  }, [newSelectedItems, selectedItems]);

  const removedItems = useMemo(() => {
    return initialSelectedItemsRef.current.filter(({ id }) =>
      selectedItems.every(({ id: prevSelectedId }) => id !== prevSelectedId),
    );
  }, [selectedItems]);

  const filteredItems = useMemo(
    () =>
      cachedData
        ? getFilteredItems({
            activatableTvPackageIds: activatableTvPackageIds ?? [],
            filter: selectedFilter,
            data: cachedData,
          })
        : [],
    [cachedData, activatableTvPackageIds, selectedFilter],
  );

  const onSelectableItemChange = useCallback(
    (item: SelectableItem) => {
      const newSelectedItems = item.isSelected
        ? [item, ...selectedItems]
        : selectedItems.filter(({ id }) => id !== item.id);

      setSelectedItems(newSelectedItems);
      setDirty(areSelectedItemsDifferent(newSelectedItems, initialSelectedItemsRef.current));
    },
    [selectedItems],
  );

  const onOpenItemModal = useCallback((item: SelectableItem | FixedItem) => {
    itemModalRef.current?.open(item);
  }, []);

  const refresh = useCallback(async () => {
    await refreshSelectionMenu();
  }, [refreshSelectionMenu]);

  const reset = useCallback(async () => {
    // Since we can't await the refresh, we're setting the "resetting" state to true,
    // so we can set it to false when the data is refreshed
    setResetting(true);
    refresh();
  }, [refresh]);

  const save = useCallback(async () => {
    try {
      setSaving(true);
      await setSelectionMenu(selectedItems.map(({ id }) => id));
      // Since we have new data, we can now say that we are not dirty anymore
      setDirty(false);
      saveConfirmModalRef.current?.open({ newSelectedItems, removedItems });
      refresh();
    } catch (err) {
      logger.error('Failed to save selection menu', err);
      createNegativeSnackbar(t('ERROR_GENERIC_INFORMATION'));
    } finally {
      setSaving(false);
    }
    refresh();
  }, [createNegativeSnackbar, logger, newSelectedItems, refresh, removedItems, selectedItems, t]);

  if (error) {
    return <ErrorComponent error={error} />;
  }

  return (
    <SelectionMenuContext.Provider
      value={{
        selectedPoints,
        totalPoints: cachedData?.pointsTotal ?? 0,
        newSelectedItems,
        oldSelectedItems,
        removedItems,
        fixedItems: cachedData?.fixed ?? [],
        dirty,
        openItemModal: onOpenItemModal,
        onSelectableItemChange,
        reset,
        save,
        saving,
        resetting,
        // We're only considered "loading" when we don't have any cached data
        loading: !cachedData,
      }}
    >
      <Fullscreen>
        <Filters
          selectedFilter={selectedFilter}
          onFilterChange={(filter) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setSelectedFilter(filter);
          }}
        />
        <StyledContainer>
          <SelectionMenuGridView items={filteredItems} />
          <SelectionMenuScoreTable />
        </StyledContainer>
      </Fullscreen>
      <ItemModal ref={itemModalRef} />
      <SaveConfirmModal ref={saveConfirmModalRef} />
    </SelectionMenuContext.Provider>
  );
};
