import { NavigatorSection } from 'pages';
import { StyledNavigationContainer, StyledNavLink } from './NavigationLarge.styles';

type NavigatorLargeProps = {
  sections: NavigatorSection[];
};

export const NavigationLarge = ({ sections: navigatorSections }: NavigatorLargeProps) => {
  return (
    <StyledNavigationContainer>
      {navigatorSections.map(({ icon, path, label }, i) => (
        <StyledNavLink key={i} to={path}>
          {icon}
          <span>{label}</span>
        </StyledNavLink>
      ))}
    </StyledNavigationContainer>
  );
};
